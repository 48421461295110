import Header from './Header.js';

export default function Home() {
    return <>
        <Header />
        <section className='hero' id="hero">
            <div className='home-hero-text'>
                <h1> Hello! 👋 I'm Andrew Dawson</h1>
                <h3 style={{width: '70%', margin:'0 auto'}}>Backend Software Developer, Cloud Architect & Physicist</h3>
            </div>
            
        </section>
        {/* <section className='about' id="about">
            <h2 className='section-title'>About Me</h2>
            <div class="two-column-grid-container">
                <div class="left-column">
                    <h1>An Introduction to me</h1>
                    <br></br>
                    <p>I'm an experienced backend software developer with a Masters in Theoretical Physics, specializing in architecting high-performance 
                        APIs and scalable data pipelines. My expertise lies in seamlessly managing communication from thousands of devices and orchestrating 
                        the analysis and storage of millions of data points. Beyond crafting efficient solutions for the present challenges of IoT, I'm fueled 
                        by a passion for the future of quantum technologies and computing, ensuring a forward-looking approach to emerging trends.</p>
                    <br></br>
                    <p>In my portfolio, you'll find a showcase of projects that highlight my ability to navigate the complexities of backend development.
                         My commitment to excellence and future-proof solutions positions me as a dedicated professional ready to shape the trajectory of technology. 
                         Let's embark on a journey to push the boundaries and craft innovative solutions together.</p>
                </div>
                <div class="right-column">
                    <h1>Technology Stack</h1>
                    <div className='technology-stack-container p-30 horizontal-flex-container flex-wrap'>
                        <img className='svg-icon' src='/img/technology-stack-images/python.svg'/>
                        <img className='svg-icon' src='/img/technology-stack-images/css.svg'/>
                        <img className='svg-icon' src='/img/technology-stack-images/html.svg'/>
                        <img className='svg-icon' src='/img/technology-stack-images/js.svg'/>
                        <img className='svg-icon' src='/img/technology-stack-images/react.svg'/>
                    </div>
                    <h1>AWS Qualifications</h1>
                    <div className='technology-stack-container p-30 horizontal-flex-container'>
                        <a target='_blank' href='https://www.credly.com/badges/088b3314-8638-48b9-a089-7f6fa418c99f/public_url'><img className='aws-qualification-img' src='https://www.credly.com/badges/088b3314-8638-48b9-a089-7f6fa418c99f/public_url' src='/img/technology-stack-images/aws-certified-cloud-practitioner.png'/></a>
                    </div>
                </div>
            </div>
        </section> */}
        <section className='experience' id="experience">
            <h2 className='section-title'>Experience & Education</h2>
            <div className='experience-item-container'>
                <div className='experience-item'>
                    <div className='horizontal-flex-container gap-20'>
                        <img src="/img/cthings-logo.png"/>
                        <div className='experience-item-content'>
                            <h3>Backend Software Developer</h3>
                            <p>cThings Limited · Full-time</p>
                            <p>Jun 2022 - Present</p>
                            <p>Cambridge, England, United Kingdom</p>
                        </div>
                    </div>
                </div>
                <div className='experience-item'>
                    <div className='horizontal-flex-container gap-20'>
                        <img src="/img/mytutor-logo.png"/>
                        <div className='experience-item-content'>
                            <h3>(A-Level / GCSE) Physics and Mathematics Tutor</h3>
                            <p>MyTutor.co.uk · Self-employed</p>
                            <p>Jan 2020 - Present</p>
                        </div>
                    </div>
                </div>
                <div className='experience-item'>
                    <div className='horizontal-flex-container gap-20'>
                        <img src="/img/nottingham-university-logo.png"/>
                        <div className='experience-item-content'>
                            <h3>Quantum Information and Cold Atoms Summer Research Intern</h3>
                            <p>University of Nottingham · Internship</p>
                            <p>Jul 2021 - Sep 2021</p>
                            <p>Nottingham, England, United Kingdom</p>
                        </div>
                    </div>
                </div>
                <div className='experience-item'>
                    <div className='horizontal-flex-container gap-20'>
                        <img src="/img/nottingham-university-logo.png"/>
                        <div className='experience-item-content'>
                            <h3>MSci Physics with Theoretical Physics</h3>
                            <p>University of Nottingham · Internship</p>
                            <p>Sep 2018 - Sep 2021</p>
                            <p>Nottingham, England, United Kingdom</p>
                        </div>
                    </div>
                </div>
                <div className='experience-item'>
                    <div className='horizontal-flex-container gap-20'>
                        <img src="/img/nuffield-logo.png"/>
                        <div className='experience-item-content'>
                            <h3>Statistical Analysis based Nuffield Research Placement</h3>
                            <p>Nuffield Foundation · Internship</p>
                            <p>Jun 2017 - Sep 2017</p>
                            <p>Newcastle upon Tyne, England, United Kingdom</p>
                        </div>
                    </div>
                </div>
            </div>

        </section>
        <section className='projects' id="projects">
            <h2 className='section-title'>Projects</h2>
            <div className='projects-card-container horizontal-flex-container flex-wrap center'>
                <div className='project-card'>
                    <div className='project-card-preview'>
                        <img className='svg-icon' src='/img/infinite-ink-preview.png'/>
                    </div>
                    <div className='project-card-content'>
                        <div className='project-card-title'>
                            <h2>InfiniteInk</h2>
                        </div>
                        <div className='project-card-description'>
                            <p>An online collaboration whiteboard tool supporting chat and video calls.</p>
                        </div>
                    </div>
                </div>
                <div className='project-card'>
                    <div className='project-card-preview'>
                        <img className='svg-icon' src='/img/text-analyser-tool.png'/>
                    </div>
                    <div className='project-card-content'>
                        <div className='project-card-title'>
                            <h2>Text Analyser Tool</h2>
                        </div>
                        <div className='project-card-description'>
                            <p>An analytical text tool for calculating the number of words,
                                sentences, paragraphs and estimate reading time of a given piece of text.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <footer>
            <div className='footer-container'>
                <div className='footer-content-container horizontal-flex-container align-center space-between'>
                    <div className='footer-about-me-text'>
                        <h3>Andrew Dawson</h3>
                        <p>Backend Software Developer and Theoretical Physicist</p>
                    </div>
                    <div className='footer-social-media-group horizontal-flex-container align-center'>
                        <a target='_blank' href='https://uk.linkedin.com/in/andrew--dawson'><img className='svg-icon' src='/img/social-media-icons/linkedin.svg'/></a>
                    </div>
                </div>
                <div className='footer-copyright'>
                    <hr></hr>
                    <p>© Copyright 2024. Created By Andrew Dawson</p>
                </div>
            </div>
        </footer>
    </>
}
