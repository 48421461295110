import React, { useState, useEffect, useRef } from 'react';

export default function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const headerRef = useRef(null);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const closeNavOnClick = (event) => {
    console.log('ran');
    console.log(headerRef.current)
    // console.log(headerRef.current.contains(event.target))
  
    if (headerRef.current && !headerRef.current.contains(event.target)) {
      setIsNavOpen(false);
    }
  };

  // Add event listener to close the navigation menu when clicking outside the header
  useEffect(() => {
    document.addEventListener('click', closeNavOnClick);

    return () => {
      document.removeEventListener('click', closeNavOnClick);
    };
  }, []);

  return (
    <header ref={headerRef}>
      <div className='header horizontal-flex-container align-center '>
        <div className='header-content-container'>
          <div className='header-logo-container horizontal-flex-container align-center h-inherit'>
            <img className='header-logo-img br-20 h-inherit' src='/img/profile-picture.png' alt='Profile'/>
            <span className='header-logo-name'><b>Andrew Dawson</b></span>
          </div>
          <div className={`header-nav ${isNavOpen ? 'open' : ''}`}>
            <div className='hamburger' onClick={toggleNav}>
              <div className='bar'></div>
              <div className='bar'></div>
              <div className='bar'></div>
            </div>
            <ul className={isNavOpen ? 'open' : ''}>
              <a href='#hero' onClick={toggleNav}><li><b>HOME</b></li></a>
              {/* <a href='#about' onClick={toggleNav}><li><b>ABOUT ME</b></li></a> */}
              <a href='#experience' onClick={toggleNav}><li><b>EXPERIENCE & EDUCATION</b></li></a>
              <a href='#projects' onClick={toggleNav}><li><b>PROJECTS</b></li></a>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}
